/*--------------------------
  variables
---------------------------*/
//fonts
$primary-font: 'Poppins', sans-serif;

//colors
$primary-color: #3F434A;
$secondary-color: #723DED;

/*--------------------------
  base settings
---------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  // border: solid 2px red;
}

html {
  // font-size: 62.5%; // 10px
  font-size: 60%; // 10px
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  
  @media only screen and (max-width: 62.5em) { // tab-land
      font-size: 56.25%;
  }
  
  @media only screen and (max-width: 56.25em) { // tab-port
      font-size: 54%; 
  }

  @media only screen and (max-width: 37.5em) { // phone
      font-size: 51%; 
  }
  
  @media only screen and (min-width: 112.5em) { // big desktop
      font-size: 75%; // 1rem = 12px, 12/16 = 75
  }
  
}

body {
  font-family: $primary-font;
  font-size: 1.6rem;
  overflow-x: hidden;
  color: $primary-color;
  background-color: #eee;
}

*::-webkit-scrollbar {
  width: .3rem;
} 
*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.layout {
  display: grid;
  grid-template-columns: repeat(12, minmax(min-content, 10rem));
  grid-template-rows: 10rem auto;
}