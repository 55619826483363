$secondary-color: #723ded;

.chatDetailsWrapper {
  position: relative;
  max-width: 70rem;
  margin: 0 auto;
  background-color: #fff;
  height: 100vh;
  font-family: 'Poppins', sans-serif;

  display: flex;
  flex-direction: column;

  header {
    padding: 1.5rem 3rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    position: fixed;
    width: 70rem;
    top: 0;
    height: 10vh;
    z-index: 10;
    div {
      display: flex;
      align-items: center;
      p {
        margin-left: 1.75rem;
        margin-top: 1.25rem;
      }
      &:last-child {
        margin-left: auto;
      }
    }
  }

  .main {
    background-color: #fff;
    height: 80vh;
    overflow: scroll;
    width: 100%;
    position: absolute;
    top: 10vh;
    left: 0;
    display: flex;
    flex-direction: column;
    font-size: 80%;
    z-index: 5;
    scroll-behavior: smooth;
    overflow-x: hidden;

    .userMessage {
      background-color: $secondary-color;
            padding: 1.5rem;
            color: white;
            border-radius: 10px 10px 10px 0;
            position: relative;
            display: block;
            max-width: 30rem;

            span {
                content: ' ';
                position: absolute;
                width: 3.75rem;
                left: -30px;
                bottom: 0;
                background-image: url('../../assests/svg/pSubtract.svg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: left;
            }
    }
    .agentMessage {
      background-color: white;
      padding: 1.5rem;
      color: #595F69;
      border-radius: 10px 10px 0px 10px;
      border: 1px solid #E8E9EB;
      position: relative;
      display: block;
      margin-right: 1rem;
      max-width: 30rem;
    }
  }

  footer {
    padding: 1rem 3rem;
    border-top: 1px solid #eee;
    position: fixed;
    width: 70rem;
    height: 10vh;
    margin: 0 auto;
    background-color: #fff;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 37.5em) { // tab-land
      width: 100vw;
  }
    form {
      display: flex;
      align-items: center;
      width: 100%;
      .emojiWrapper {
        position: relative;
        margin-right: 1rem;
        img {
          cursor: pointer;
        }
        span {
          position: absolute;
          bottom: 30px;
          left: 10px;
        }
        .showEmojiBox {
            display: none;
        }
      }

      .inputMessage {
        width: 85%;
        padding: 1rem;
        outline: none;
        border: none;
        font-family: inherit;
      }
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
  }
}

.showEmojiBox.open {
    display: block !important;
}

.attachement {
    margin-right: 1rem;
    max-width: 100%;
    object-fit: cover;
    input {
      display: none;
    }
  }

  .agentAccepted {
    color: #95A1AA;
    align-self: center;
    display: flex;
    align-items: center;
  
    svg {
      margin-right: .5rem;
      margin-top: -.2rem;
    }
  }